import React from 'react'
import Helmet from 'react-helmet'
import '../assets/scss/main.scss'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div className={`body`}>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>B.House | Home</title>
            <link rel="canonical" href="https://bhouse.design/" />
            <meta name="title" content="B.House | Let's build your dream workspace!" />
            <meta name="description" content="B.House is an office furniture dealer located in Miami, Florida. We offer our clients an array of U.S. and International brands that will enhance any commercial environment. We also offer flexible financing options that can reduce your organization's tax liability." />
            <meta name="keywords" content="office furniture, commercial furniture, miami, coral gables, south florida, florida, healthcare furniture, education furniture, hospitality furniture, retail furniture, workstations, ergonomic seating, desks, demountable walls, office design, corporate interiors, corporate art, furniture financing, modern office furniture, soft seating" />
            <meta name="p:domain_verify" content="63be8dec517c8731f85cb34f668ec04a"/>
            {/* Open Tags */}
            <meta property="og:url" content="bhouse.design" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="B.House Design" />
            <meta property="og:image" content="https://miro.medium.com/fit/c/128/128/2*VLk1BZvqCFQm5bOWOLLRmA.jpeg" />
            <meta property="og:description" content="A commercial furniture and product company committed to delivering smart furniture solutions for creatively minded companies." />
          </Helmet>
          {children}
        </div>
      </div>
    )
  }
}

export default Template
