import React from 'react'
import Scroll from './Scroll'
import { Link } from 'gatsby'
import logo from '../assets/images/bhouse-logo-black.svg'
import facebook from '../assets/images/icon-facebook.svg'
import instagram from '../assets/images/icon-instagram.svg'
import linkedin from '../assets/images/icon-linkedin.svg'
import pinterest from '../assets/images/icon-pinterest.svg'

const Nav = ({ location = "top" }) => (
  <nav id="nav" className={location}>
    <div className="logo">
      <Scroll type="id" element="nav">
        <Link to="/">
          <img src={logo} alt="B House" />
        </Link>
      </Scroll>
    </div>
    <div className="menu">
      <ul>
        <li>
          <Scroll type="id" element="about-us">
            <button>About us</button>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="preferred-brands-container">
            <button>Brands</button>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="how-we-work">
            <button>How we work</button>
          </Scroll>
        </li>
        <li>
          <Scroll type="id" element="contact-us">
            <button>Contact us</button>
          </Scroll>
        </li>
        <li>
          <a href="https://medium.com/@B.House" target="_blank" rel="noopener noreferrer">Blog</a>
        </li>
        <div className="icons">
          <li>
            <a href="https://www.facebook.com/Bhouse-Design-104734634484312/" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="facebook" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/bhousedesign" target="_blank" rel="noopener noreferrer">
              <img src={instagram} alt="instagram" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/bhousedesign" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt="linked in" />
            </a>
          </li>
          <li>
            <a href="https://www.pinterest.com/bhousedesigns" target="_blank" rel="noopener noreferrer">
              <img src={pinterest} alt="pinterest" />
            </a>
          </li>
        </div>
      </ul>
    </div>
  </nav>
)
export default Nav
